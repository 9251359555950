import React from "react";
import { RouteInfo } from "../interfaces";
import Defaults from "./Defaults";
import Operator from "./Operator";
import { Switch, Route } from "react-router-dom";
import NotFound from "../errors/NotFound";
import Unsigned from "./Unsigned";
import Client from "./Client";
import Tutor from "./Tutor";
import { User, useUser } from "../globals/user";

function generateRoutes(routesInfo: RouteInfo[]) {
  return routesInfo.map((route, index) => (
    <Route
      exact={route.exact}
      path={route.path}
      key={index}
      component={route.component}
    />
  ));
}

interface RouteInfoProps {
  [key: string]: RouteInfo[];
}

function hierarchyRoutes(user: User | null, routeInfos: RouteInfoProps) {
  let res: RouteInfo[] = [];
  // handle case for unsigned first
  if (!user) {
    res = [...res, ...routeInfos.unsigned];
  } else {
    // we have signed now
    // push default client view
    res = [...res, ...routeInfos.client];
    // push route if hierarchy is admin or operator
    if (["admin", "operator1", "operator2"].includes(user.role)) {
      res = [...res, ...routeInfos.operator];
    }
    if (user.tutor_id) {
      res = [...res, ...routeInfos.tutor];
    }
  }
  return res;
}

function Routers() {
  const user = useUser();

  return (
    <Switch>
      {generateRoutes(
        hierarchyRoutes(user, {
          unsigned: Unsigned,
          client: Client,
          operator: Operator,
          tutor: Tutor,
        })
      )}
      {generateRoutes(Defaults)}
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routers;
