import React from "react";
import { HomeDialog } from "../../../components/Home";
import { usePrivacyAgreement } from "../../../components/Policies";
import { isMobile } from "../../../utils";

interface AgreementDialogProps {
  open: boolean;
  handleClose: () => void;
}

function AgreementDialog({ open, handleClose }: AgreementDialogProps) {
  const privacyAgreement = usePrivacyAgreement();
  if (!privacyAgreement) return null;
  return (
    <HomeDialog open={open} handleClose={handleClose}>
      <div style={{ width: isMobile() ? "100vw" : "60vw", padding: 30 }}>
        <div className="font-header">이용약관</div>
        <div
          className="outline-with-border overflow-scroll"
          style={{
            marginTop: 20,
            height: 200,
            padding: 10,
          }}
        >
          <div className="font-sub whitespace-preline">
            {privacyAgreement.agreement.content}
          </div>
        </div>
        <div className="font-header" style={{ marginTop: 30 }}>
          개인정보 처리방침
        </div>
        <div
          className="outline-with-border overflow-scroll"
          style={{
            marginTop: 20,
            height: 200,
            padding: 10,
          }}
        >
          <div className="font-sub whitespace-preline">
            {privacyAgreement.privacy.content}
          </div>
        </div>
      </div>
    </HomeDialog>
  );
}

export default AgreementDialog;
