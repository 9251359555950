import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Feedbacks } from "../../interfaces";
import CustomText from "../customs/CustomText";
import CustomButton from "../customs/CustomButton";
import CustomSelect from "../customs/CustomSelect";
import CustomCheckbox from "../customs/CustomCheckbox";
import { fetchDefaultWithCredential, isMobile } from "../../utils";
import { useHistory, useLocation } from "react-router";
import { useCertification } from "../Pay";
import { SnackbarContext } from "../../globals/components/ComponentsWrapper";
import SignUpJpg from "../../pngs/signup.jpg";
import AgreementDialog from "../../globals/components/dialogs/AgreementDialog";
import Logo from "./Logo";

export const jobList = [
  {
    label: "선택",
    value: "",
    disabled: true,
  },
  {
    label: "직장인",
    value: "직장인",
  },
  {
    label: "학생",
    value: "학생",
  },
  {
    label: "학부모",
    value: "학부모",
  },
  {
    label: "주부",
    value: "주부",
  },
  {
    label: "취업준비생",
    value: "취업준비생",
  },
  {
    label: "개인사업자",
    value: "개인사업자",
  },
  {
    label: "프리랜서",
    value: "프리랜서",
  },
  {
    label: "교육관련자",
    value: "교육관련자",
  },
  {
    label: "기타 (직접 입력)",
    value: "기타 (직접 입력)",
  },
];

export const howPhilousList = [
  {
    label: "선택",
    value: "",
    disabled: true,
  },
  { label: "유튜브", value: "유튜브" },
  { label: "인스타그램", value: "인스타그램" },
  { label: "블로그/카페", value: "블로그/카페" },
  { label: "페이스북", value: "페이스북" },
  { label: "조니토크 방송", value: "조니토크 방송" },
  {
    label: "신문/뉴스기사",
    value: "신문/뉴스기사",
  },
  { label: "지인 추천", value: "지인 추천" },
  { label: "기타 (직접 입력)", value: "기타 (직접 입력)" },
];

export interface SignupForm {
  password: string;
  confirm_password: string;
  private_info: boolean;
  marketing_info: boolean;
  email: string;
  name: string;
  phone: string;
  gender: string;
  date_of_birth: string;
  job: string;
  job_detail?: string;
  how_philous: string;
  how_philous_detail?: string;
}

export const formatPhone = (phoneNumber: string) => {
  const phoneNumberLength = phoneNumber.length;
  // check for greater than 3, less than 8
  if (phoneNumberLength > 3 && phoneNumberLength < 8) {
    return (
      phoneNumber.substring(0, 3) +
      "-" +
      phoneNumber.substring(3, phoneNumberLength)
    );
  }
  // check for greater than 7
  if (phoneNumberLength > 7) {
    return (
      phoneNumber.substring(0, 3) +
      "-" +
      phoneNumber.substring(3, 7) +
      "-" +
      phoneNumber.substring(7, 11)
    );
  }
  return phoneNumber;
};

interface UnauthorizedProps {
  form: SignupForm;
  setForm: (form: SignupForm) => void;
  handleNext: () => void;
}

function Unauthorized({ form, setForm, handleNext }: UnauthorizedProps) {
  const { handleCertify } = useCertification();

  const certify = () => {
    handleCertify(
      "signup",
      ({ date_of_birth, name, gender, phone }) => {
        setForm({
          ...form,
          date_of_birth,
          name,
          gender,
          phone,
        });
        handleNext();
      },
      () => {}
    );
  };

  return (
    <>
      <div
        style={{
          fontSize: 25,
          fontWeight: 800,
          textAlign: "center",
        }}
      >
        필로어스 회원가입
      </div>
      <div className="text-align-center" style={{ marginTop: 10 }}>
        필로어스에 오신 것을 환영합니다!
      </div>
      <div
        style={{
          marginTop: 30,
        }}
      >
        <Button
          style={{
            backgroundColor: "#6939CD",
            padding: 15,
            borderRadius: 10,
            color: "white",
            fontWeight: 700,
          }}
          fullWidth
          onClick={certify}
        >
          휴대전화 인증하기
        </Button>
      </div>
      <div style={{ marginTop: 30, fontSize: 14, textAlign: "center" }}>
        필로어스 멤버는 휴대전화 인증이 필수입니다.
        <br />
        인증이 어려운 분들(해외거주자 및 외국인 등)은{" "}
        <a
          href="https://pf.kakao.com/_xnsACT/chat"
          target="_blank"
          rel="noreferrer"
        >
          고객센터
        </a>
        로 문의해주세요.
      </div>
    </>
  );
}

interface AuthorizedProps {
  form: SignupForm;
  setForm: React.Dispatch<React.SetStateAction<SignupForm>>;
  handleNext: () => void;
}

interface RecommendVerified {
  verified: boolean;
  name?: string;
}

export function Authorized({ form, setForm, handleNext }: AuthorizedProps) {
  const [feedbacks, setFeedbacks] = useState<Feedbacks>({});
  const [agreementDialog, setAgreementDialog] = useState<boolean>(false);
  const [recommendVerified, setRecommendVerified] =
    useState<RecommendVerified | null>(null);
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    delete feedbacks[target.name];
    setFeedbacks({
      ...feedbacks,
    });
    setForm((form) => ({
      ...form,
      [target.name]: target.value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchDefaultWithCredential(`/auth/signup`, "POST", form)
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            let tempFeed = {};
            for (const [field, message] of Object.entries(data)) {
              if (message) {
                tempFeed = {
                  ...tempFeed,
                  [field]: {
                    stauts: false,
                    message: message,
                  },
                };
              }
            }
            setFeedbacks(tempFeed);
            // move the scroll
            for (const key in tempFeed) {
              if (!formRef) continue;
              const inputElement = formRef.querySelector(
                `[name="${key}"]`
              ) as HTMLInputElement;
              if (inputElement) {
                inputElement.scrollIntoView({ behavior: "smooth" });
                inputElement.focus();
                break;
              }
            }
            return new Promise(() => null);
          });
        }
      })
      .then(handleNext)
      .catch((err) => console.log(err));
  };

  const handleVerifyRecommend = () => {
    if (form.how_philous_detail) {
      fetchDefaultWithCredential(`/recommend/verify`, "POST", {
        code: form.how_philous_detail,
      }).then((res) => {
        if (!res.ok) {
          return res.json().then(() => {
            return showWarningSnackbar("인증에 실패하였습니다");
          });
        }
        return res
          .json()
          .then(setRecommendVerified)
          .then(() => showSuccessSnackbar("추천인이 인증되었습니다"));
      });
    }
  };

  return (
    <>
      <form onBlur={handleChange} onSubmit={handleSubmit} ref={setFormRef}>
        <div>
          <CustomText
            width="100%"
            type="email"
            label="*이메일"
            name="email"
            feedback={feedbacks.email}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <CustomText
            width="100%"
            type="password"
            label="*비밀번호"
            name="password"
            placeholder="영문 대/소문자, 숫자, 특수문자 포함 8~16자리"
            feedback={feedbacks.password}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <CustomText
            width="100%"
            type="password"
            label="*비밀번호 재확인"
            name="confirm_password"
            feedback={feedbacks.confirm_password}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <CustomSelect
            name="job"
            label="*직업"
            items={jobList}
            value={form.job}
            feedback={feedbacks.job}
            handleChange={(value) => {
              form.job = value as string;
              setForm({ ...form });
            }}
            fullWidth
          />
        </div>
        {form.job === "기타 (직접 입력)" && (
          <div style={{ marginTop: 15 }}>
            <CustomText
              width="100%"
              type="text"
              label="*직업 직접 입력"
              name="job_detail"
              feedback={feedbacks.job_detail}
            />
          </div>
        )}
        <div style={{ marginTop: 15 }}>
          <CustomSelect
            name="how_philous"
            label="*오시게 된 경로"
            items={howPhilousList}
            value={form.how_philous}
            feedback={feedbacks.how_philous}
            handleChange={(value) => {
              form.how_philous = value as string;
              setForm({ ...form });
            }}
            fullWidth
          />
        </div>
        {form.how_philous === "지인 추천" && (
          <div style={{ marginTop: 15 }}>
            <div className="d-flex align-items-flex-end">
              <div style={{ flexGrow: 1 }}>
                <CustomText
                  width="100%"
                  type="text"
                  label={
                    recommendVerified?.verified ? "추천인" : "*추천인 코드"
                  }
                  name="how_philous_detail"
                  feedback={feedbacks.how_philous_detail}
                  disabled={recommendVerified?.verified ?? false}
                  defaultValue={recommendVerified?.name ?? undefined}
                  key={Number(recommendVerified?.verified)}
                />
              </div>
              <div className="ml-10">
                <CustomButton
                  className="height-50"
                  onClick={handleVerifyRecommend}
                >
                  인증하기
                </CustomButton>
              </div>
            </div>
          </div>
        )}
        {form.how_philous === "기타 (직접 입력)" && (
          <div style={{ marginTop: 15 }}>
            <CustomText
              width="100%"
              type="text"
              label="*오시게 된 경로 직접 입력"
              name="how_philous_detail"
              feedback={feedbacks.how_philous_detail}
            />
          </div>
        )}
        <div
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 14,
              fontWeight: "bold",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setAgreementDialog(true)}
          >
            *이용약관 및 개인정보 처리방침 동의 (필수)
          </div>
          <CustomCheckbox
            checked={form.private_info}
            handleChange={(checked) => {
              form.private_info = checked;
              setForm({ ...form });
            }}
            feedback={feedbacks.private_info}
            border={false}
            labelPlacement="end"
          >
            동의합니다
          </CustomCheckbox>
        </div>
        <div
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            마케팅 정보 수집 활용 동의 (선택)
          </div>
          <CustomCheckbox
            checked={form.marketing_info}
            handleChange={(checked) => {
              form.marketing_info = checked;
              setForm({ ...form });
            }}
            feedback={feedbacks.marketing_info}
            border={false}
            labelPlacement="end"
          >
            동의합니다
          </CustomCheckbox>
        </div>
        <div
          style={{
            fontSize: 12,
            color: "#767676",
          }}
        >
          마케팅 정보 수집 활용에 동의하지 않으시면 개별 쿠폰 발급 및 회원
          혜택이 제한됩니다.
        </div>
        <div style={{ marginTop: 30 }}>
          {(() => {
            const disabled = (() => {
              if (!form.private_info) return true;
              if (recommendVerified && !recommendVerified.verified) return true;
              return false;
            })();
            return (
              <Button
                style={{
                  backgroundColor: disabled ? "#767676" : "#6939CD",
                  padding: 15,
                  borderRadius: 10,
                  color: "white",
                  fontWeight: 700,
                }}
                fullWidth
                disabled={disabled}
                type="submit"
              >
                회원가입 완료
              </Button>
            );
          })()}
        </div>
      </form>
      <AgreementDialog
        open={agreementDialog}
        handleClose={() => setAgreementDialog(false)}
      />
    </>
  );
}

interface SignedUpProps {
  handleNext: () => void;
}

function SignedUp({ handleNext }: SignedUpProps) {
  return (
    <div style={{ textAlign: "center" }}>
      <Logo />
      <div style={{ fontSize: 20 }}>
        필로어스 멤버가 되신 것을
        <br />
        <span
          style={{
            fontSize: 36,
            fontWeight: 700,
          }}
        >
          환영합니다!
        </span>
      </div>
      <div style={{ marginTop: 30 }}>
        <Button
          style={{
            backgroundColor: "#6939CD",
            padding: 15,
            borderRadius: 10,
            color: "white",
            fontWeight: 700,
          }}
          fullWidth
          onClick={handleNext}
        >
          로그인 하기
        </Button>
      </div>
    </div>
  );
}

type SignupStep = "unauthorized" | "authorized" | "signed_up";

function Signup() {
  const [form, setForm] = useState<SignupForm>({
    email: "",
    password: "",
    confirm_password: "",
    name: "",
    phone: "",
    gender: "",
    private_info: false,
    marketing_info: true,
    date_of_birth: "",
    job: "",
    how_philous: "",
  });
  const history = useHistory();
  const [step, setStep] = useState<SignupStep>("unauthorized");
  const location = useLocation();
  const { showWarningSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (!location.state) return;
    const { mobile_verified } = location.state as any;
    if (!mobile_verified) {
      showWarningSnackbar("인증에 실패하셨습니다");
    } else {
      showSuccessSnackbar("인증에 성공 하셨습니다");
      const { gender, date_of_birth, phone, name } = location.state as any;
      setForm((form) => ({
        ...form,
        gender,
        phone,
        name,
        date_of_birth,
      }));
      setStep("authorized");
    }
  }, [location, setForm, showSuccessSnackbar, showWarningSnackbar]);

  return (
    <div style={{ display: isMobile() ? undefined : "flex" }}>
      <div
        style={{
          flex: 1,
          backgroundImage: `url(${SignUpJpg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: isMobile() ? 120 : 800,
        }}
      ></div>
      <div
        style={{
          flex: 1,
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile() ? undefined : 400,
            margin: "0px auto",
            padding: isMobile() ? "50px 20px" : "50px 0px",
          }}
        >
          {step === "unauthorized" && (
            <Unauthorized
              form={form}
              setForm={setForm}
              handleNext={() => setStep("authorized")}
            />
          )}
          {step === "authorized" && (
            <Authorized
              form={form}
              setForm={setForm}
              handleNext={() => setStep("signed_up")}
            />
          )}
          {step === "signed_up" && (
            <SignedUp handleNext={() => history.push("/signin")} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Signup;
