import React, { useContext, useRef, useState } from "react";
import {
  fetchDefaultWithCredential,
  isMobile,
  putImageWithToken,
} from "../../../utils";
import CustomButton from "../../customs/CustomButton";
import CustomText from "../../customs/CustomText";
import CustomSelect from "../../customs/CustomSelect";
import CustomCheckbox from "../../customs/CustomCheckbox";
import { howPhilousList, jobList } from "../../layouts/Signup";
import { useOptions } from "../../admins/pages/Options";
import CustomAvatar from "../../customs/CustomAvatar";
import { useUser } from "../../../globals/user";
import { GlobalContext } from "../../../globals/components/ComponentsWrapper";
import { ReactComponent as EditPencilIcon } from "../../../svg/edit_pencil.svg";
import { Button } from "@mui/material";
import { HomeDialog } from "../../Home";
import AgreementDialog from "../../../globals/components/dialogs/AgreementDialog";

function Info() {
  const hiddenFileInput = useRef<any>(null);
  const [agreementDialog, setAgreementDialog] = useState<boolean>(false);
  const [deactivateDialog, setDeactivateDialog] = useState<boolean>(false);
  const user = useUser();
  const [deactiveReason, setDeactiveReason] = useState<string>("");
  const [deactiveErrorMessage, setDeactiveErrorMessage] = useState<string>("");
  const { options } = useOptions("personal_goal");
  const { refreshUser } = useContext(GlobalContext);

  const handleClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    hiddenFileInput.current.click();
  };

  const handleImageChange = (e: any) => {
    const files = e.target.files;
    if (files.length === 0) return;
    const file = e.target.files[0];
    fetchDefaultWithCredential(`/user/profile_image`, "PUT", {
      filename: file.name,
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.text().then((token: string) => {
          putImageWithToken(token, file).then(refreshUser);
        });
      })
      .catch((err) => console.log(err));
  };

  if (!user || !options) return null;

  const handleSubmit = () => {
    fetchDefaultWithCredential(`/user/?type=info`, "PUT", {
      marketing_info: user.meta.marketing_info,
      job: user.job,
      intro: user.meta.intro || "",
      job_detail: user.meta.job_detail || "",
      how_philous: user.meta.how_philous || "",
      how_philous_detail: user.meta.how_philous_detail || "",
      personal_goal_option_id: user.meta.personal_goal_option_id ?? 0,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((data) => {
          throw new Error(data);
        });
      }
      return refreshUser();
    });
  };

  const submitDeactiveReason = () => {
    fetchDefaultWithCredential(`/user/${user.id!}/deactivate`, "PUT", {
      reason: deactiveReason,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          setDeactiveErrorMessage(error);
        });
      }
      return res.json().then(() => {
        window.location.reload();
      });
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", inset: 0, zIndex: -1 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ height: "500px", backgroundColor: "#313131" }}></div>
          <div style={{ flex: 1, backgroundColor: "#F2F2F2" }}></div>
        </div>
      </div>
      <div
        style={{
          paddingTop: isMobile() ? 50 : 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: isMobile() ? 150 : 170,
            height: isMobile() ? 150 : 170,
            position: "relative",
            border: "6px solid #FFFFFF",
            borderRadius: "50%",
          }}
        >
          <CustomAvatar user={user} />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
          >
            <EditPencilIcon
              style={{
                cursor: "pointer",
              }}
              onClick={handleClick}
            />
            <input
              type="file"
              name="profile_image"
              ref={hiddenFileInput}
              onChange={handleImageChange}
              style={{ display: "none" }}
              accept="image/*"
            />
          </div>
        </div>
        <div
          style={{
            marginTop: 15,
            fontWeight: 700,
            color: "#FFFFFF",
            fontSize: 25,
          }}
        >
          {user.name} 님의 프로필
        </div>
      </div>
      <div
        style={{
          width: "80%",
          margin: "0 auto",
          padding: isMobile() ? "25px 0" : "50px 0",
        }}
      >
        <div
          style={{
            borderRadius: isMobile() ? 20 : "70px 70px 30px 30px",
            backgroundColor: "white",
            padding: isMobile() ? 25 : 50,
          }}
        >
          <div>
            <CustomSelect<number>
              label="나만의 목표 선택"
              name="personal_goal"
              value={user.meta.personal_goal_option_id || 0}
              items={[
                {
                  label: "목표 선택하기",
                  value: 0,
                  disabled: true,
                },
                ...options.map((option) => ({
                  label: option.label,
                  value: option.id as number,
                })),
              ]}
              handleChange={(value) => {
                user.meta.personal_goal_option_id = value;
                handleSubmit();
              }}
              fullWidth
            />
          </div>
          <div style={{ marginTop: isMobile() ? 15 : 30 }}>
            <CustomText
              width="100%"
              type="text"
              multiline
              label="자기소개"
              placeholder="나를 소개하는 말을 자유롭게 적어주세요."
              name="intro"
              height="120px"
              defaultValue={user.meta.intro}
              handleBlur={(e) => {
                user.meta.intro = e.target.value;
                handleSubmit();
              }}
            />
          </div>
          <div style={{ marginTop: isMobile() ? 15 : 30 }}>
            <CustomText
              width="100%"
              type="email"
              label="이메일 주소"
              name="email"
              defaultValue={user.email}
              readOnly
            />
          </div>
          <div
            style={{
              marginTop: isMobile() ? 15 : 30,
              display: isMobile() ? undefined : "flex",
              columnGap: 30,
            }}
          >
            <div style={{ flex: 1 }}>
              <div>
                <CustomSelect<string>
                  label="*직업"
                  name="job"
                  value={user.job}
                  items={jobList}
                  handleChange={(value) => {
                    user.job = value;
                    handleSubmit();
                  }}
                  fullWidth
                />
              </div>
              {user.job === "기타 (직접 입력)" && (
                <div style={{ marginTop: 30 }}>
                  <CustomText
                    width="100%"
                    type="text"
                    label="*직업 직접 입력"
                    name="job_detail"
                    defaultValue={user.meta.job_detail}
                    handleBlur={(e) => {
                      user.meta.job_detail = e.target.value;
                      handleSubmit();
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                flex: isMobile() ? undefined : 1,
                marginTop: isMobile() ? 15 : 0,
              }}
            >
              <div>
                <CustomSelect<string>
                  label="*오시게 된 경로"
                  name="how_philous"
                  value={user.meta.how_philous}
                  items={howPhilousList}
                  handleChange={(value) => {
                    user.meta.how_philous = value;
                    handleSubmit();
                  }}
                  fullWidth
                />
              </div>
              {user.meta.how_philous === "기타 (직접 입력)" && (
                <div style={{ marginTop: 30 }}>
                  <CustomText
                    width="100%"
                    type="text"
                    label="*오시게 된 경로 직접 입력"
                    name="how_philous_detail"
                    defaultValue={user.meta.how_philous_detail}
                    handleBlur={(e) => {
                      user.meta.how_philous_detail = e.target.value;
                      handleSubmit();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 700,
                  fontSize: isMobile() ? 12 : 18,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setAgreementDialog(true)}
              >
                *개인정보 수집 활용 동의 (필수)
              </div>
              <CustomCheckbox
                border={false}
                checked={user.meta.private_info}
                handleChange={(checked) => {
                  user.meta.private_info = checked;
                  handleSubmit();
                }}
                disabled
              >
                동의합니다
              </CustomCheckbox>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 700,
                  fontSize: isMobile() ? 12 : 18,
                }}
              >
                *마케팅 정보 수집 활용 동의 (선택)
              </div>
              <CustomCheckbox
                border={false}
                checked={user.meta.marketing_info}
                handleChange={(checked) => {
                  console.log(checked);
                  user.meta.marketing_info = checked;
                  handleSubmit();
                }}
              >
                동의합니다
              </CustomCheckbox>
            </div>
          </div>
          <div style={{ marginTop: 50, textAlign: "center", marginBottom: 50 }}>
            <Button
              onClick={() => setDeactivateDialog(true)}
              style={{
                border: "1px solid #000000",
                borderRadius: 36,
                fontSize: isMobile() ? 16 : 18,
                padding: isMobile() ? "10px 30px" : "15px 50px",
              }}
            >
              계정 비활성화 하기
            </Button>
          </div>
        </div>
      </div>
      <AgreementDialog
        open={agreementDialog}
        handleClose={() => setAgreementDialog(false)}
      />
      <HomeDialog
        open={deactivateDialog}
        handleClose={() => setDeactivateDialog(false)}
      >
        <div
          className="text-align-center"
          style={{
            padding: 30,
          }}
        >
          <p className="font-header">
            계정을 비활성화 하시는 <br /> 이유가 무엇인가요?
          </p>
          <p
            className="font-15"
            style={{
              color: "#7f7f7f",
            }}
          >
            계정을 비활성화 하실 경우, 모든 프로그램 신청이 불가능하며
            <br />
            멤버쉽 권한이 즉시 사라지게 됩니다.
          </p>
          <p
            className="font-15"
            style={{
              color: "#f0142f",
            }}
          >
            프로그램 혹은 멤버쉽 환불을 원하실 경우,
            <br />
            계정 비활성화 전 필로어스 고객센터(카카오톡 플러스친구 채널)로
            <br />
            문의를 주셔야 환불이 가능한 점 알려드립니다.
          </p>
          <p
            className="font-12"
            style={{
              color: "#f0142f",
            }}
          >
            비활성화가 아닌, 이용자의 동의 철회(회원 탈퇴)를 원하시는 경우
            <br />
            필로어스 고객센터를 통해 별도로 연락을 주시면 진행을
            도와드리겠습니다.
            <br />
            (웹사이트 하단 고객문의 → 문의하기)
          </p>

          <div className="mt-30">
            <p className="font-14">계정을 비활성화 하는 이유</p>
            <CustomText
              width="100%"
              type="text"
              name="deactive_reason"
              height="150px"
              placeholder="이유를 작성해주세요"
              multiline
              style={{ backgroundColor: "white" }}
              handleBlur={(e) => setDeactiveReason(e.target.value)}
            />
          </div>
          {deactiveErrorMessage && (
            <div className="mt-10 error font-small">{deactiveErrorMessage}</div>
          )}
          <div
            style={{
              marginTop: "50px",
              textAlign: "center",
            }}
          >
            <CustomButton
              width={100}
              height={40}
              outlined
              onClick={submitDeactiveReason}
              style={{ marginRight: "15px" }}
            >
              비활성화 하기
            </CustomButton>
          </div>
        </div>
      </HomeDialog>
    </div>
  );
}

export default Info;
