import { Hidden } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AdminPage, ResponsivePage } from "./admins/pages/edits/Page";
import { fetchDefaultWithCredential } from "../utils";

interface PageComponentProps {
  responsivePage?: ResponsivePage;
}

function PageComponent({ responsivePage }: PageComponentProps) {
  if (!responsivePage) return null;
  return (
    <div className="d-flex p-relative">
      <img
        src={responsivePage.image}
        alt=""
        className="width-100-percentage"
        draggable={false}
      />
      {responsivePage.buttons.map((button) => (
        <div
          key={button.id}
          className="p-absolute"
          onClick={() => window.open(button.link, "_blank")}
          style={{
            transform: "translate(-50%, -50%)",
            top: `${button.top_percentage}%`,
            left: `${button.left_percentage}%`,
            cursor: "pointer",
            zIndex: 1,
            width: `${button.width_percentage || 0}%`,
          }}
        >
          <img
            src={button.image}
            alt=""
            draggable={false}
            style={{ width: "100%" }}
          />
        </div>
      ))}
    </div>
  );
}

function CustomPage() {
  const location = useLocation();
  const splittedLocation = location.pathname.split("/");
  const pageId = parseInt(splittedLocation[splittedLocation.length - 1]);
  const [adminPage, setAdminPage] = useState<AdminPage | null>(null);

  useEffect(() => {
    fetchDefaultWithCredential(`/admin/page/pages/${pageId}`, "GET").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ message }) => {
            throw new Error(message);
          });
        }
        return res.json().then(setAdminPage);
      }
    );
  }, [pageId]);

  if (!adminPage) return null;

  return (
    <>
      <Hidden smDown>
        <PageComponent responsivePage={adminPage.meta.desktop} />
      </Hidden>
      <Hidden smUp>
        <PageComponent responsivePage={adminPage.meta.mobile} />
      </Hidden>
    </>
  );
}

export default CustomPage;
