import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/Menu";
import { Badge, Drawer, Grid, Hidden, IconButton, Link } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "../../globals/components/ComponentsWrapper";
import { useNotifications } from "../../globals/notification";
import { useUser } from "../../globals/user";
import { ReactComponent as CartNavbar } from "../../svg/cart.svg";
import { fetchDefaultWithCredential, isEligible } from "../../utils";
import CustomAvatar from "../customs/CustomAvatar";
import "./Navbar.scss";
import Logo from "./Logo";
import { AdminPage } from "../admins/pages/edits/Page";

function MyPageComponent() {
  const user = useUser();
  const notifications = useNotifications();
  if (!user) return null;
  return (
    <Link
      component={NavLink}
      to={`/mypage`}
      color="inherit"
      underline="none"
      className="font-bold font-13"
    >
      <Badge badgeContent={notifications.user?.total ?? 0} color="secondary">
        <div className="width-40 height-40">
          <CustomAvatar user={user} />
        </div>
      </Badge>
    </Link>
  );
}

export function handleSignout() {
  return fetchDefaultWithCredential("/user/persistence/remove", "GET").then(
    (res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json();
    }
  );
}

// const PhiloTrip = () => (
//   <Link
//     component={NavLink}
//     to="/philotrip"
//     color="inherit"
//     underline="none"
//     className="font-bold font-13"
//   >
//     필로트립
//   </Link>
// );

// const AdultProgram = () => (
//   <Link
//     component={NavLink}
//     to="/program"
//     color="inherit"
//     underline="none"
//     className="font-bold font-13"
//   >
//     필로토크
//   </Link>
// );

// const PhiloPreceptorial = () => (
//   <Link
//     component={NavLink}
//     to="/philo-preceptorial"
//     color="inherit"
//     underline="none"
//     className="font-bold font-13"
//   >
//     필로프리셉 100
//   </Link>
// );

const Signin = () => (
  <Link
    component={NavLink}
    to="/signin"
    underline="none"
    color="inherit"
    className="outline-with-border p-10 font-bold font-13"
  >
    로그인
  </Link>
);

const Community = () => (
  <Link
    component={NavLink}
    to="/community"
    color="inherit"
    underline="none"
    className="font-bold font-13"
  >
    커뮤니티
  </Link>
);

const Cart = () => (
  <Link
    component={NavLink}
    to="/cart"
    color="inherit"
    underline="none"
    className="font-bold font-13"
  >
    <div className="d-flex cart">
      <CartNavbar />
    </div>
  </Link>
);

const Signout = () => {
  const history = useHistory();
  const { refreshUser } = useContext(GlobalContext);
  return (
    <Link
      underline="none"
      className="outline-with-border p-10 font-bold font-13"
      style={{ cursor: "pointer" }}
      onClick={() =>
        handleSignout().then(() => {
          localStorage.removeItem("credential");
          refreshUser().then(() => history.replace("/"));
        })
      }
    >
      로그아웃
    </Link>
  );
};

function Tutor() {
  const notifications = useNotifications();
  return (
    <Badge
      badgeContent={notifications.tutor?.total ?? 0}
      color="secondary"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Link
        component={NavLink}
        to="/tutor/management"
        color="inherit"
        underline="none"
        className="outline-with-border p-10 font-bold font-13"
      >
        TUTOR
      </Link>
    </Badge>
  );
}

function Admin() {
  const notifications = useNotifications();
  return (
    <Badge
      badgeContent={notifications.admin?.total ?? 0}
      color="secondary"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Link
        component={NavLink}
        to="/admin/management"
        color="inherit"
        underline="none"
        className="outline-with-border p-10 font-bold font-13"
      >
        ADMIN
      </Link>
    </Badge>
  );
}

interface DrawerListProps {
  adminPages: AdminPage[];
}

function DrawerList({ adminPages }: DrawerListProps) {
  const user = useUser();
  return (
    <div style={{ width: "250px", height: "100%" }}>
      <Grid container direction="column">
        {user && (
          <div className="p-30" style={{ backgroundColor: "#959595" }}>
            <div className="d-flex align-items-center">
              <div>
                <MyPageComponent />
              </div>
              <div className="ml-20">
                <div
                  className="font-14 font-bold"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {user.name}
                </div>
                <div
                  style={{
                    marginTop: "5px",
                    fontSize: "10px",
                    color: "white",
                  }}
                >
                  {user.email}
                </div>
              </div>
            </div>
          </div>
        )}
      </Grid>
      <div className="p-30">
        <div>
          <Logo />
        </div>
        {/* <div className="mt-30">
          <Home />
        </div> */}
        {adminPages
          .filter((adminPage) => adminPage.meta.visualize)
          .map((adminPage, index) => (
            <div className="mt-30" key={index}>
              <Link
                component={NavLink}
                to={`/pages/${adminPage.id}`}
                color="inherit"
                underline="none"
                className="font-bold font-13"
              >
                {adminPage.meta.title || ""}
              </Link>
            </div>
          ))}
        {/* <div className="mt-30">
          <PhiloTrip />
        </div> */}
        {/* <div className="mt-30">
          <AdultProgram />
        </div> */}
        {/* <div className="mt-30">
          <PhiloPreceptorial />
        </div> */}
        <div className="mt-30">
          <Community />
        </div>
        <div className="mt-30">
          {!user ? (
            <Fragment>
              <div>
                <Signin />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div>
                <Cart />
              </div>
              <div className="d-flex mt-30">
                {isEligible(user, "operator2") && (
                  <div>
                    <Admin />
                  </div>
                )}
                {user.tutor_id && (
                  <div className="ml-10">
                    <Tutor />
                  </div>
                )}
                <div className="ml-10">
                  <Link
                    component={NavLink}
                    to="/mypage"
                    color="inherit"
                    underline="none"
                    className="outline-with-border p-10 font-bold font-13"
                  >
                    마이 페이지
                  </Link>
                </div>
                <div className="ml-10">
                  <Signout />
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

function Navbar() {
  const user = useUser();
  const [drawer, setDrawer] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const [adminPages, setAdminPages] = useState<AdminPage[] | null>(null);

  useEffect(() => {
    setDrawer(false);
  }, [location]);

  useEffect(() => {
    fetchDefaultWithCredential(`/admin/page/pages`, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(setAdminPages);
    });
  }, []);

  if (!adminPages) return null;

  return (
    <Fragment>
      <Hidden smDown>
        <div className="navbar-container p-static">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs>
              <div className="d-flex align-items-center user-select-none p-relative">
                <div
                  className="d-flex cursor-pointer align-items-center"
                  onClick={() => history.push("/")}
                >
                  <Logo />
                </div>
                <div
                  className="d-flex ml-10"
                  style={{
                    border: "1px solid #C9B9DD",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={() => setToggle(!toggle)}
                >
                  {!toggle ? (
                    <KeyboardArrowDownIcon
                      style={{
                        color: "#4A148C",
                      }}
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      style={{
                        color: "#4A148C",
                      }}
                    />
                  )}
                </div>
                <div
                  className="p-absolute font-noto-regular"
                  style={{
                    backgroundColor: "#4A148C",
                    borderRadius: "50px",
                    display: toggle ? "block" : "none",
                    top: "100%",
                    zIndex: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://precept.philous.co.kr", "_blank");
                    setToggle(!toggle);
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ padding: "20px 50px" }}
                  >
                    <div>
                      <div className="text-white font-20 font-bold">
                        필로프리셉 100
                      </div>
                      <div
                        className="font-12 mt-5"
                        style={{ color: "#D9D9D9" }}
                      >
                        학생 대상 고전독서 토론 논술 교육
                      </div>
                    </div>
                    <div className="ml-20 font-16" style={{ color: "#D9D9D9" }}>
                      바로가기
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            {/* <Grid item>
              <Home />
            </Grid> */}
            {adminPages
              .filter((adminPage) => adminPage.meta.visualize)
              .map((adminPage, index) => (
                <Grid item key={index}>
                  <Link
                    component={NavLink}
                    to={`/pages/${adminPage.id}`}
                    color="inherit"
                    underline="none"
                    className="font-bold font-13"
                  >
                    {adminPage.meta.title || ""}
                  </Link>
                </Grid>
              ))}
            <Grid item>
              <Community />
            </Grid>
            {user ? (
              <Fragment>
                {isEligible(user, "operator2") && (
                  <Grid item>
                    <Admin />
                  </Grid>
                )}
                {user.tutor_id && (
                  <Grid item>
                    <Tutor />
                  </Grid>
                )}
                <Grid item>
                  <Signout />
                </Grid>
                <Grid item>
                  <MyPageComponent />
                </Grid>
                <Grid item>
                  <Cart />
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid item>
                  <Signin />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="p-30 p-sticky">
          <div className="p-relative">
            <div className="d-flex justify-content-center">
              <Logo />
            </div>
            <div style={{ position: "absolute", top: 0, left: 0 }}>
              <IconButton onClick={() => setDrawer(true)} size="large">
                <MenuIcon fontSize="large" />
              </IconButton>
            </div>
          </div>
        </div>
        <Drawer anchor="left" open={drawer} onClose={() => setDrawer(false)}>
          <DrawerList adminPages={adminPages} />
        </Drawer>
      </Hidden>
    </Fragment>
  );
}

export default Navbar;
