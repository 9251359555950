import React, { Fragment, useRef } from "react";
import { StatusProps } from "../../interfaces";

interface PropType {
  label?: string;
  value?: string;
  feedback?: StatusProps;
  width?: string;
  height?: string;
  type: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  feedbackMark?: boolean;
  multiline?: boolean;
  style?: object;
  labelStyle?: object;
  autocomplete?: string;
  defaultValue?: string | number;
  readOnly?: boolean;
  smallText?: string;
  handleChange?: (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleBlur?: (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

function CustomText({
  label,
  value,
  feedback,
  width,
  height,
  type,
  name,
  placeholder,
  disabled,
  maxLength,
  feedbackMark = true,
  multiline,
  style,
  labelStyle,
  autocomplete,
  defaultValue,
  readOnly,
  handleChange,
  handleBlur,
  smallText,
}: PropType) {
  const ref = useRef<any>(null);
  const handleChangeWrapper = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (type === "phone") {
      // we should only let value match the format
      // figure out the one that just typed
      const target = e.target;
      let value = target.value as string;
      // find very last index if it's not empty string
      let refined = value.replace(/\D/g, "");
      if (refined === "") {
        target.value = "";
        return;
      }
      if (refined.length > 11) {
        refined = refined.substring(0, 11);
      }
      if (refined.length < 4) {
        target.value = refined;
      } else if (refined.length < 8) {
        const substr = refined.substring(0, 3);
        target.value = substr + "-" + refined.substring(3);
      } else {
        const substr = refined.substring(0, 3);
        const substr2 = refined.substring(3, 7);
        target.value = substr + "-" + substr2 + "-" + refined.substring(7);
      }
    }
    if (handleChange) {
      handleChange(e);
    }
    if (handleBlur) {
      handleBlur(e);
    }
  };

  const styleWrapper = {
    padding: 15,
    borderRadius: 13,
    lineHeight: 1,
    width: width ?? "360px",
    border: "1px solid #AAAAAA",
    backgroundColor: (() => {
      if (readOnly) {
        return "#ECECEC";
      }
      if (feedback && !feedback.status) {
        return "#FF000426";
      }
      return "white";
    })(),
    fontSize: 18,
    resize: "none",
    ...style,
  } as React.CSSProperties;

  return (
    <Fragment>
      {label && (
        <div
          style={{
            fontSize: 18,
            fontWeight: 700,
            marginBottom: 10,
            ...labelStyle,
          }}
        >
          {label}
        </div>
      )}
      {smallText && <p className="font-small">{smallText}</p>}
      <div className="p-relative">
        {multiline ? (
          <textarea
            ref={ref}
            name={name}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            maxLength={maxLength}
            defaultValue={defaultValue}
            readOnly={readOnly}
            onChange={(e) => {
              if (e.target.name === "phone" || handleChange) {
                handleChangeWrapper(e as any);
              }
            }}
            onBlur={handleBlur && handleChangeWrapper}
            style={styleWrapper}
          />
        ) : (
          <input
            ref={ref}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            maxLength={maxLength}
            autoComplete={autocomplete}
            defaultValue={defaultValue}
            readOnly={readOnly}
            onChange={(e) => {
              if (e.target.name === "phone" || handleChange) {
                handleChangeWrapper(e as any);
              }
            }}
            onBlur={handleBlur && handleChangeWrapper}
            style={styleWrapper}
          />
        )}
        {feedback && !feedback.status && feedback.message && (
          <div className="mt-5 text-red font-14">{feedback.message}</div>
        )}
      </div>
    </Fragment>
  );
}

export default CustomText;
