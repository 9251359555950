import React, { useEffect, useState } from "react";
import CustomButton from "../../../customs/CustomButton";
import CustomSelect from "../../../customs/CustomSelect";
import { fetchDefaultWithCredential } from "../../../../utils";
import CustomText from "../../../customs/CustomText";
import CustomCheckbox from "../../../customs/CustomCheckbox";
import CustomImageUploadButton from "../../../customs/CustomImageUploadButton";
import { postImageFile } from "../../../../hooks/useImages";
import { v4 } from "uuid";

interface ResponsiveEditComponentProps {
  label: string;
  responsivePage: ResponsivePage;
  handleEdit: () => void;
}

function ResponsiveEditComponent({
  label,
  responsivePage,
  handleEdit,
}: ResponsiveEditComponentProps) {
  return (
    <div>
      <div className="font-header">{label}</div>
      <div className="mt-30">
        <div>
          <CustomImageUploadButton
            fileChangeCallback={(file) => {
              postImageFile(file).then((image) => {
                responsivePage.image_id = image.id;
                responsivePage.image = image.location;
                handleEdit();
              });
            }}
          >
            페이지 이미지 선택
          </CustomImageUploadButton>
        </div>
        <div className="mt-30">
          <div className="width-300">
            <img
              src={responsivePage.image}
              alt=""
              className="width-100-percentage"
              draggable={false}
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <CustomButton
          onClick={() => {
            responsivePage.buttons.push({
              id: v4(),
              image_id: 0,
              image: "",
              left_percentage: 0,
              link: "",
              top_percentage: 0,
              width_percentage: 0,
            });
            handleEdit();
          }}
        >
          버튼 추가
        </CustomButton>
      </div>
      <div className="mt-10">
        {responsivePage.buttons.map((button) => (
          <div key={button.id} className="pt-10">
            <div>
              <CustomButton
                onClick={() => {
                  responsivePage.buttons = responsivePage.buttons.filter(
                    (iterButton) => iterButton.id !== button.id
                  );
                  handleEdit();
                }}
              >
                삭제
              </CustomButton>
            </div>
            <div className="mt-10">
              <div>
                <CustomImageUploadButton
                  fileChangeCallback={(file) => {
                    postImageFile(file).then((image) => {
                      button.image_id = image.id;
                      button.image = image.location;
                      handleEdit();
                    });
                  }}
                >
                  버튼 이미지 선택
                </CustomImageUploadButton>
              </div>
              <div className="mt-10">
                <div className="width-300">
                  <img
                    src={button.image}
                    alt=""
                    className="width-100-percentage"
                    draggable={false}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <CustomText
                label="가로 길이 %"
                name="width_percentage"
                type="number"
                width="100%"
                defaultValue={button.width_percentage || 0}
                handleBlur={(e) => {
                  button.width_percentage = parseFloat(e.target.value) || 0;
                  handleEdit();
                }}
              />
            </div>
            <div className="mt-10">
              <CustomText
                label="위쪽으로부터의 위치 %"
                name="top_percentage"
                type="number"
                width="100%"
                defaultValue={button.top_percentage || 0}
                handleBlur={(e) => {
                  button.top_percentage = parseFloat(e.target.value) || 0;
                  handleEdit();
                }}
              />
            </div>
            <div className="mt-10">
              <CustomText
                label="왼쪽으로부터의 위치 %"
                name="left_percentage"
                type="number"
                width="100%"
                defaultValue={button.left_percentage || 0}
                handleBlur={(e) => {
                  button.left_percentage = parseFloat(e.target.value) || 0;
                  handleEdit();
                }}
              />
            </div>
            <div className="mt-10">
              <CustomText
                label="링크"
                name="link"
                type="text"
                width="100%"
                defaultValue={button.link || ""}
                handleBlur={(e) => {
                  button.link = e.target.value;
                  handleEdit();
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface AdminPageDetailProps {
  adminPage: AdminPage;
  refresh: () => void;
}

function AdminPageDetail({ adminPage, refresh }: AdminPageDetailProps) {
  const handleEdit = () => {
    fetchDefaultWithCredential(
      `/admin/page/pages/${adminPage.id}`,
      "PUT",
      adminPage.meta
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(refresh);
    });
  };

  const handleDelete = () => {
    fetchDefaultWithCredential(
      `/admin/page/pages/${adminPage.id}`,
      "DELETE"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(refresh);
    });
  };

  if (adminPage.meta.visualize === undefined) {
    adminPage.meta.visualize = false;
  }

  if (adminPage.meta.title === undefined) {
    adminPage.meta.title = "";
  }

  if (adminPage.meta.desktop === undefined) {
    adminPage.meta.desktop = {
      image_id: 0,
      image: "",
      buttons: [],
    };
  }

  if (adminPage.meta.mobile === undefined) {
    adminPage.meta.mobile = {
      image_id: 0,
      image: "",
      buttons: [],
    };
  }

  console.log(adminPage);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: 300 }}>
        <div>
          <CustomButton onClick={handleDelete}>페이지 삭제</CustomButton>
        </div>
        <div className="mt-10">
          <CustomCheckbox
            border={false}
            checked={adminPage.meta.visualize}
            handleChange={(checked) => {
              adminPage.meta.visualize = checked;
              handleEdit();
            }}
          >
            보여지기
          </CustomCheckbox>
        </div>
        <div className="mt-10">
          <CustomText
            label="페이지 이름"
            name="title"
            type="text"
            width="100%"
            defaultValue={adminPage.meta.title || ""}
            handleBlur={(e) => {
              adminPage.meta.title = e.target.value;
              handleEdit();
            }}
          />
        </div>
      </div>
      <div style={{ marginLeft: 30, flex: 1 }}>
        <ResponsiveEditComponent
          label="데스크탑"
          responsivePage={adminPage.meta.desktop}
          handleEdit={handleEdit}
        />
      </div>
      <div style={{ marginLeft: 30, flex: 1 }}>
        <ResponsiveEditComponent
          label="모바일"
          responsivePage={adminPage.meta.mobile}
          handleEdit={handleEdit}
        />
      </div>
    </div>
  );
}

interface ResponsivePageButton {
  id: string;
  image_id: number;
  image: string;
  top_percentage: number;
  left_percentage: number;
  link: string;
  width_percentage: number;
}

export interface ResponsivePage {
  image_id: number;
  image: string;
  buttons: ResponsivePageButton[];
}

interface AdminPageMeta {
  title?: string;
  visualize?: boolean;
  desktop?: ResponsivePage;
  mobile?: ResponsivePage;
}

export interface AdminPage {
  id: number;
  created_at: string;
  meta: AdminPageMeta;
}

function AdminPagesEditsPage() {
  const [adminPages, setAdminPages] = useState<AdminPage[] | null>(null);
  const [render, setRender] = useState<number>(0);
  const [currentAdminPageId, setCurrentAdminPageId] = useState<number>(0);

  const refresh = () => setRender((render) => render + 1);

  useEffect(() => {
    fetchDefaultWithCredential(`/admin/page/pages`, "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(setAdminPages);
    });
  }, [render]);

  const handleAdd = () => {
    fetchDefaultWithCredential(`/admin/page/pages`, "POST").then((res) => {
      if (!res.ok) {
        return res.json().then(({ message }) => {
          throw new Error(message);
        });
      }
      return res.json().then(refresh);
    });
  };

  if (!adminPages) return null;

  const adminPage = adminPages.find(
    (adminPage) => adminPage.id === currentAdminPageId
  );

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: 300 }}>
        <div>
          <CustomButton onClick={handleAdd}>페이지 추가</CustomButton>
        </div>
        <div className="mt-10">
          <CustomSelect
            name="page"
            label="페이지"
            items={[
              {
                label: "선택 안함",
                value: 0,
                disabled: true,
              },
              ...adminPages.map((adminPage) => ({
                label: adminPage.meta.title || "새 페이지",
                value: adminPage.id,
              })),
            ]}
            value={currentAdminPageId}
            handleChange={(value) => setCurrentAdminPageId(value as number)}
          />
        </div>
      </div>
      {adminPage && (
        <div style={{ marginLeft: 30, flex: 1 }}>
          <AdminPageDetail adminPage={adminPage} refresh={refresh} />
        </div>
      )}
    </div>
  );
}

export default AdminPagesEditsPage;
