import { Link } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

function Logo() {
  return (
    <Link component={NavLink} to="/" underline="none" color="inherit">
      <div
        className="align-items-center height-60"
        style={{ display: "inline-flex" }}
      >
        <img src="/philous_logo.png" alt="philous" style={{ height: "100%" }} />
        <div
          className="font-24 font-bold"
          style={{
            color: "#3A1994",
          }}
        >
          PHILOUS
        </div>
      </div>
    </Link>
  );
}

export default Logo;
