import {
  Grid,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import React, { useState, Fragment, useEffect } from "react";
import { useTutors, useUsers } from "../../../hooks";
import { Tutor } from "../../../interfaces";
import {
  fetchDefaultWithCredential,
  month,
  putImageWithToken,
} from "../../../utils";
import CustomAvatar, {
  CustomAvatarWithLocation,
} from "../../customs/CustomAvatar";
import CustomButton from "../../customs/CustomButton";
import SortComponent from "./helpers/SortComponent";
import CustomText from "../../customs/CustomText";
import CustomCheck from "../../customs/CustomCheck";
import CustomDialog from "../../customs/CustomDialog";
import { User } from "../../../globals/user";
import { HomeDialog } from "../../Home";

// function TutorAttendedPrograms({ tutor }: TutorAttendedProgramsProps) {
//   const [page, setPage] = useState<number>(1);
//   const { tutorProgramReviews } = useTutorProgramReviews(tutor.id!, page);
//   const [currentProgramReview, setCurrentProgramReview] =
//     useState<ProgramReview | null>(null);

//   if (!tutorProgramReviews) return null;
//   return (
//     <Grid container>
//       <Grid item style={{ width: "300px" }}>
//         <SortComponent
//           currentDataObject={currentProgramReview}
//           setCurrentDataObject={setCurrentProgramReview}
//           label="후기"
//           data={tutorProgramReviews.program_reviews}
//           listItemComponent={(programReview: ProgramReview) => (
//             <Grid container alignItems="center">
//               <Grid item xs={9}>
//                 <p className="font-16 text-ellipsis">{programReview.detail}</p>
//                 <p className="font-12 text-secondary">
//                   {programReview.program.meta.program_name}
//                 </p>
//               </Grid>
//               <Grid item xs={3}>
//                 <p className="font-bold text-align-center">
//                   {programReview.user.name}
//                 </p>
//               </Grid>
//             </Grid>
//           )}
//           abcSortAttributeFunc={(programReview) => programReview.detail}
//           defaultSortType="recent"
//         />
//         <div className="mt-10 d-flex justify-content-center">
//           <Pagination
//             page={page}
//             count={tutorProgramReviews.total_pages}
//             color="standard"
//             onChange={(event, value) => setPage(value)}
//           />
//         </div>
//       </Grid>
//       {currentProgramReview && (
//         <Grid item xs className="ml-30">
//           <ProgramReviewComponent programReview={currentProgramReview} />
//         </Grid>
//       )}
//     </Grid>
//   );
// }

interface OpenReviewSelectDialogProps {
  open: boolean;
  handleClose: () => void;
  tutorId: number;
}

interface UserProgramReview {
  id: number;
  program_id: number;
  program_image: string;
  program_header: string;
  program_name: string;
  program_tutor_ids: number[];
  program_review_date: string;
  program_review_detail: string;
  program_reviewed_user_name: string;
  program_review_active: boolean;
  program_review_best: boolean;
  votes: number;
  voted: boolean;
}

interface TutorReviewTutor {
  id: number;
  created_at: string;
  user_id: number;
  tutor_name: string;
  introduction: string;
  status: string;
  meta: {
    tutor_selected_reviews: {
      [review_id: number]: {
        active: boolean;
        title: string;
      };
    };
  };
  name: string;
  tutor_avatar: string;
  discount_percentage: number;
}

interface TutorReview {
  tutor: TutorReviewTutor;
  user_program_reviews: UserProgramReview[];
  total_pages: number;
}

function OpenReviewSelectDialog({
  open,
  handleClose,
  tutorId,
}: OpenReviewSelectDialogProps) {
  const [tutorReview, setTutorReview] = useState<TutorReview | null>(null);
  const [currentReview, setCurrentReview] = useState<UserProgramReview | null>(
    null
  );
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchDefaultWithCredential(
      `/user/tutor/${tutorId}/program/reviews?page=${1}&amount=${10000}`,
      "GET"
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setTutorReview);
    });
  }, [render, tutorId]);

  if (!tutorReview) return null;

  const handleEdit = () => {
    fetchDefaultWithCredential(
      `/user/tutor/${tutorId}/program/reviews/admin`,
      "PUT",
      {
        tutor_selected_reviews: tutorReview.tutor.meta.tutor_selected_reviews,
      }
    ).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(() => setRender(render + 1));
    });
  };

  return (
    <HomeDialog open={open} handleClose={handleClose}>
      <div className="p-30">
        <div className="d-flex" style={{ height: 800, overflow: "auto" }}>
          <div style={{ width: 300 }}>
            <SortComponent
              currentDataObject={currentReview}
              setCurrentDataObject={setCurrentReview}
              label="후기들"
              data={tutorReview.user_program_reviews}
              listItemComponent={(user_program_review) => (
                <div>
                  <div style={{ fontSize: 18, fontWeight: 700 }}>
                    {user_program_review.program_name}
                  </div>
                  <div
                    style={{
                      marginTop: 5,
                      fontSize: 14,
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {user_program_review.program_review_detail}
                  </div>
                </div>
              )}
              abcSortAttributeFunc={(user_program_review) =>
                user_program_review.program_name
              }
            />
          </div>
          <div className="ml-30" style={{ width: 500 }}>
            {(() => {
              if (!currentReview) return null;
              const tutor_selected_reviews =
                tutorReview.tutor.meta.tutor_selected_reviews || {};
              const tutor_selected_review = tutor_selected_reviews[
                currentReview.id
              ] || { active: false, title: "" };
              return (
                <Fragment key={currentReview.id}>
                  <div className="mt-10">{currentReview.program_name}</div>
                  <div className="mt-10">
                    <img
                      src={currentReview.program_image}
                      alt=""
                      style={{ height: 100, width: "auto" }}
                    />
                  </div>
                  <div className="mt-10">
                    {currentReview.program_reviewed_user_name}
                  </div>
                  <div className="mt-10">
                    {currentReview.program_review_detail}
                  </div>
                  <div className="mt-10">
                    튜터 선정 제목 활성화: (
                    {tutor_selected_review.active ? "O" : "X"})
                  </div>
                  <div className="mt-10">
                    <CustomButton
                      onClick={() => {
                        tutor_selected_review.active =
                          !tutor_selected_review.active;
                        tutor_selected_reviews[currentReview.id] =
                          tutor_selected_review;
                        tutorReview.tutor.meta.tutor_selected_reviews =
                          tutor_selected_reviews;
                        handleEdit();
                      }}
                    >
                      {tutor_selected_review.active ? "비활성화" : "활성화"}
                    </CustomButton>
                  </div>
                  <div className="mt-10">
                    <CustomText
                      width="100%"
                      type="text"
                      label="튜터 선정 제목"
                      name="tutor_selected_title"
                      defaultValue={tutor_selected_review.title}
                      handleBlur={(e) => {
                        tutor_selected_review.title = e.target.value;
                        tutor_selected_reviews[currentReview.id] =
                          tutor_selected_review;
                        tutorReview.tutor.meta.tutor_selected_reviews =
                          tutor_selected_reviews;
                        handleEdit();
                      }}
                    />
                  </div>
                </Fragment>
              );
            })()}
          </div>
        </div>
      </div>
    </HomeDialog>
  );
}

interface CurrentTutorProfileComponentProps {
  tutor: Tutor;
  patchTutorStatus: (tutorId: number) => Promise<void>;
  deleteTutor: (tutorId: number) => Promise<void>;
  refreshTutors: () => void;
}

interface TutorForm {
  tutor_avatar: string;
  tutor_name: string;
  introduction: string;
  detail: string;
  hashtags: string[];
  discount_percentage: number;
}

function CurrentTutorProfileComponent({
  tutor,
  patchTutorStatus,
  deleteTutor,
  refreshTutors,
}: CurrentTutorProfileComponentProps) {
  const [tutorForm, setTutorForm] = useState<TutorForm>({
    tutor_avatar: tutor.tutor_avatar ?? "",
    tutor_name: tutor.tutor_name,
    introduction: tutor.introduction,
    detail: tutor.detail,
    hashtags: tutor.hashtags,
    discount_percentage: tutor.discount_percentage,
  });
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [openReviewSelect, setOpenReviewSelect] = useState<boolean>(false);
  const [tutorAvatarInputRef, setTutorAvatarInputRef] =
    useState<HTMLInputElement | null>(null);

  const handleEdit = () => {
    fetchDefaultWithCredential(`/tutor/${tutor.id!}`, "PUT", { ...tutorForm })
      .then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json();
      })
      .then(refreshTutors);
  };
  const handleDelete = () => {
    deleteTutor(tutor.id!);
  };

  const handleTutorAvatarChange = (e: any) => {
    const files = e.target.files;
    if (files.length === 0) return;
    const file = e.target.files[0];
    fetchDefaultWithCredential(`/tutor/${tutor.id!}/avatar`, "PUT", {
      filename: file.name,
    }).then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json().then(({ token }) => {
        putImageWithToken(token, file).then((image) => {
          tutorForm.tutor_avatar = image.location;
          setTutorForm({ ...tutorForm });
        });
      });
    });
  };

  const handleTutorAvatarDelete = (e: any) => {
    fetchDefaultWithCredential(`/tutor/${tutor.id!}/avatar`, "DELETE").then(
      (res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        return res.json().then(() => {
          tutorForm.tutor_avatar = "";
          setTutorForm({ ...tutorForm });
        });
      }
    );
  };

  return (
    <Grid container>
      <Grid item>
        <div className="d-flex justify-content-space-between">
          <CustomCheck
            label=""
            checkItems={[
              {
                label: "튜터 활성화",
                checked: tutor.status === "active",
                id: 0,
              },
            ]}
            handleChange={() => setOpenStatus(true)}
          />
          {new Date().getTime() - new Date(tutor.created_at!).getTime() <=
            3 * month && (
            <CustomButton className="background-red">New</CustomButton>
          )}
        </div>
        <div className="d-flex justify-content-center mt-10">
          <div className="width-100 height-100">
            <CustomAvatarWithLocation location={tutorForm.tutor_avatar} />
          </div>
        </div>
        <div className="mt-10 d-flex justify-content-space-evenly">
          <CustomButton
            onClick={() => {
              if (tutorAvatarInputRef) {
                tutorAvatarInputRef.click();
              }
            }}
          >
            프로필 사진 바꾸기
          </CustomButton>
          <input
            className="d-none"
            type="file"
            name="tutor_avatar"
            ref={setTutorAvatarInputRef}
            onChange={handleTutorAvatarChange}
            accept="image/*"
          />
          <CustomButton onClick={handleTutorAvatarDelete}>X</CustomButton>
        </div>
        <div className="mt-10">
          <p className="font-bold">회원 명: {tutor.name}</p>
        </div>
        <div className="mt-10">
          <p className="font-bold">
            튜터 등록 날짜:{" "}
            {new Date(tutor.created_at!).toLocaleDateString("ko-KR")}
          </p>
        </div>
        <div className="mt-10">
          <p className="font-bold">개월 수: {tutor.months}개월</p>
        </div>
        <div className="mt-10">
          <p className="font-bold">누적 횟수: {tutor.counts}회</p>
        </div>
        <div className="mt-10">
          <CustomText
            width="100%"
            type="text"
            label="튜터 명"
            name="tutor_name"
            defaultValue={tutorForm.tutor_name}
            handleBlur={(e) => {
              tutorForm.tutor_name = e.target.value;
              handleEdit();
            }}
          />
        </div>
        <div className="mt-10">
          <CustomText
            width="100%"
            type="text"
            multiline
            label="자기소개"
            name="introduction"
            height="100px"
            defaultValue={tutorForm.introduction}
            handleBlur={(e) => {
              tutorForm.introduction = e.target.value;
              handleEdit();
            }}
          />
        </div>
        <div className="mt-10">
          <CustomText
            width="100%"
            type="text"
            multiline
            label="자기소개 설명"
            name="detail"
            height="100px"
            defaultValue={tutorForm.detail}
            handleBlur={(e) => {
              tutorForm.detail = e.target.value;
              handleEdit();
            }}
          />
        </div>
        <div className="mt-10">
          <div>
            <CustomButton
              onClick={() => {
                tutorForm.hashtags = [...tutorForm.hashtags, ""];
                handleEdit();
              }}
            >
              해시태그 추가
            </CustomButton>
          </div>
          {tutorForm.hashtags.map((hashtag, index) => (
            <div className="mt-10 p-10 outline-with-border" key={index}>
              <div className="d-flex justify-content-flex-end">
                <CustomButton
                  onClick={() => {
                    tutorForm.hashtags.splice(index, 1);
                    handleEdit();
                  }}
                >
                  삭제
                </CustomButton>
              </div>
              <div className="mt-10">
                <CustomText
                  label={`해시태그 ${index + 1}`}
                  name="hashtag"
                  type="text"
                  width="100%"
                  defaultValue={hashtag}
                  handleBlur={(e) => {
                    tutorForm.hashtags[index] = e.target.value;
                    handleEdit();
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="mt-10">
          <CustomText
            width="100%"
            type="number"
            label="기본 할인률(%)"
            name="discount_percentage"
            defaultValue={tutorForm.discount_percentage || 0}
            handleBlur={(e) => {
              tutorForm.discount_percentage = Number(e.target.value);
              handleEdit();
            }}
          />
        </div>
        <div className="mt-10 d-flex justify-content-space-evenly">
          <CustomButton onClick={() => setOpenReviewSelect(true)}>
            후기 선택하기
          </CustomButton>
          <CustomButton onClick={() => setOpenDelete(true)}>
            삭제하기
          </CustomButton>
        </div>
      </Grid>
      <CustomDialog open={openStatus}>
        <p className="font-header">
          정말로 {tutor.status === "pending" ? "활성화" : "비활성화"}{" "}
          하시겠습니까?
        </p>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <CustomButton
              onClick={() => {
                if (tutor.status === "pending") {
                  tutor.status = "active";
                } else {
                  tutor.status = "pending";
                }
                patchTutorStatus(tutor.id!).then(() => setOpenStatus(false));
              }}
            >
              {tutor.status === "pending" ? "활성화" : "비활성화"}
            </CustomButton>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <CustomButton onClick={() => setOpenStatus(false)}>
              닫기
            </CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
      <CustomDialog open={openDelete}>
        <p className="font-header">정말로 삭제하시겠습니까?</p>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <CustomButton onClick={handleDelete}>삭제</CustomButton>
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <CustomButton onClick={() => setOpenDelete(false)}>
              닫기
            </CustomButton>
          </Grid>
        </Grid>
      </CustomDialog>
      <OpenReviewSelectDialog
        tutorId={tutor.id}
        open={openReviewSelect}
        handleClose={() => setOpenReviewSelect(false)}
      />
    </Grid>
  );
}

function AdminPageTutors() {
  const { users } = useUsers();
  const { tutors, refreshTutors, patchTutorStatus, deleteTutor } = useTutors();
  const tutorUserIds = tutors.map((tutor) => tutor.user_id);
  const [currentTutorUser, setCurrentTutorUser] = useState<User | null>(null);
  const currentTutor = tutors.find(
    (tutor) => tutor.user_id === currentTutorUser?.id!
  );

  const tutoredUsers = users.filter((user) => tutorUserIds.includes(user.id!));

  return (
    <div className="p-30">
      <div className="mt-10">
        <Grid container columnSpacing={4}>
          <Grid item sm={4} xs={12}>
            <div>
              <SortComponent
                currentDataObject={currentTutorUser}
                setCurrentDataObject={setCurrentTutorUser}
                label="튜터 명단"
                data={tutoredUsers}
                listItemComponent={(user) => (
                  <Fragment>
                    <ListItemAvatar>
                      <div className="width-40 height-40">
                        <CustomAvatar user={user} />
                      </div>
                    </ListItemAvatar>
                    <ListItemText primary={user.name} secondary={user.email} />
                    <ListItemSecondaryAction>
                      {(() => {
                        const tutor = tutors.find(
                          (tutor) => tutor.user_id === user.id
                        )!;
                        return (
                          <CustomButton
                            width={50}
                            height={30}
                            onClick={() =>
                              deleteTutor(tutor.id!).then(refreshTutors)
                            }
                          >
                            튜터 삭제
                          </CustomButton>
                        );
                      })()}
                    </ListItemSecondaryAction>
                  </Fragment>
                )}
                abcSortAttributeFunc={(user) => user.name}
              />
            </div>
            {/* <div className="mt-30">
              <SortComponent
                currentDataObject={currentUser}
                setCurrentDataObject={setCurrentUser}
                label="회원 명단"
                data={nonTutoredUsers}
                listItemComponent={(user) => (
                  <Fragment>
                    <ListItemAvatar>
                      <div className="width-40 height-40">
                        <CustomAvatar user={user} />
                      </div>
                    </ListItemAvatar>
                    <ListItemText primary={user.name} secondary={user.email} />
                    <ListItemSecondaryAction>
                      <CustomButton
                        width={50}
                        height={30}
                        onClick={() => postTutor(user.id!).then(refreshTutors)}
                      >
                        튜터 추가
                      </CustomButton>
                    </ListItemSecondaryAction>
                  </Fragment>
                )}
                abcSortAttributeFunc={(user) => user.name}
              />
            </div> */}
          </Grid>
          {currentTutor && (
            <Grid item sm={8} xs={12}>
              <CurrentTutorProfileComponent
                key={currentTutor.id}
                tutor={currentTutor}
                patchTutorStatus={patchTutorStatus}
                deleteTutor={deleteTutor}
                refreshTutors={refreshTutors}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default AdminPageTutors;
